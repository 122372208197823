import { Postcard } from "./Postcard";
import React from "react";
import styled from "styled-components";
import Title from "./Title";

export const Posts = () => {
  const posts = [
    {
      id: 1,
      title: "post 1",
      date: "October 2, 2019",
      author: "eamon",
      content: "insert blog post here this iaskfhkasjfhlahfjklaheflknaslf",
      category: "react",
    },
    {
      id: 2,
      title: "post 2",
      date: "October 2, 2019",
      author: "eamon",
      content: "insert blog post here this iaskfhkasjfhlahfjklaheflknaslf",
      category: "react",
    },
    {
      id: 3,
      title: "post 3",
      date: "October 2, 2019",
      author: "eamon",
      content: "insert blog post here this iaskfhkasjfhlahfjklaheflknaslf",
      category: "react",
    },
    {
      id: 4,
      title: "post 4",
      date: "October 2, 2019",
      author: "eamon",
      content: "insert blog post here this iaskfhkasjfhlahfjklaheflknaslf",
      category: "react",
    },
    {
      id: 5,
      title: "post 5",
      date: "October 2, 2019",
      author: "eamon",
      content: "insert blog post here this iaskfhkasjfhlahfjklaheflknaslf",
      category: "react",
    },
  ];

  return (
    <div>
      <Title>Blog</Title>

      <Wrapper>
        {posts.map((post) => (
          <Postcard
            key={post.id}
            title={post.title}
            date={post.date}
            author={post.author}
            content={post.content}
            category={post.category}
          ></Postcard>
        ))}
      </Wrapper>
    </div>
  );
};

export default Posts;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: center;
  column-gap: 10px;
  margin-left: 20vw;
  margin-right: 20vw;
  height: 700px;
  a{
    text-decoration: none;
    color: white;
  }
  @media (max-width: 700px) {
    margin-left: 4vw;
    margin-right: 4vw;
    flex-direction: row;
    height:140vh;
    justify-content: center;
  align-content: flex-start;
  }
`;
