import styled from "styled-components";
import { ReactComponent as Lin } from './linkedin.svg';
import { ReactComponent as Ghub } from './github.svg';

function Footer() {
  return (
    <Wrapper>
      <div id="name">
        <p>2021© Eamon Miller</p>
      </div>
      <nav>
        <a href='https://www.linkedin.com'><Lin class='icon' alt='linkden'  /></a>
        <a href='https://www.github.com'><Ghub class='icon' alt='github' /></a>
      </nav>
    </Wrapper>
  );
}
export default Footer;

const Wrapper = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  flex-wrap: wrap;
  align-content: space-between;
  justify-content: space-between;

  .icon{
      width:20px;
      padding-right: 30px;
      
  }
  p {
    font-size: 14px;
    margin-left: 10px;
  }

`;
