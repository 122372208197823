import Head from './components/Head.js'
import Posts from './components/Posts.js';
import Projects from './components/Projects.js';
import About from './components/About.js';
import Contact from './components/Contact.js';
import Footer from './components/Footer.js';
import { Routes, Route } from 'react-router-dom';
import styled from 'styled-components';



function App() {
  return (
    <Wrapper className="App">    
      <Head></Head>
      <Routes> 
        <Route path='/' element={<About />} />
        <Route path='/projects' element={<Projects />} />
        <Route path='/blog' element={<Posts />} />
        <Route path='/contact' element={<Contact />} />
      </Routes>
      <Footer></Footer>
      
    </Wrapper>
  );
}

export default App;

const Wrapper = styled.div`
display: flex;
flex-direction: column;
justify-content: space-between;
height: 100vh;
z-index: 1;

`


