import Card from "./Card";
//import { Link } from "react-router-dom";
export default function ProjectCard(props) {
  return (
    <a href={props.link}>
      <Card>
        <h2>{props.title}</h2>
        <div id='description'>
          <p>{props.about}</p>
        </div>
        <p id='stack'>{props.stack}</p>
      </Card>
    </a>
  );
}
