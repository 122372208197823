import styled from "styled-components";
import Title from "./Title";

export default function Contact() {
  return (
    <div>
      <Title>Contact</Title>
      <Form>
        <form>
          <div class="container">
            <form
              target="_blank"
              action="https://formsubmit.co/eamonspa@gmail.com"
              method="POST"
            >
              <div class="form-group">
                <div class="form-row">
                  <div class="col">
                    <input
                      type="text"
                      name="name"
                      class="form-control"
                      placeholder="Full Name"
                      required
                    />
                  </div>
                  <div class="col">
                    <input
                      type="email"
                      name="email"
                      class="form-control"
                      placeholder="Email Address"
                      required
                    />
                  </div>
                </div>
              </div>
              <div class="form-group">
                <textarea
                  placeholder="Your Message"
                  class="form-control"
                  name="message"
                  rows="10"
                  required
                ></textarea>
              </div>
              <button type="submit" class="btn btn-lg btn-dark btn-block">
                Submit Form
              </button>
            </form>
          </div>
        </form>
      </Form>
    </div>
  );
}

const Form = styled.div`
  overflow: hidden;

  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding-bottom: 20vh;
  button {
    display: inline-block;
    padding: 0.2em 1.45em;
    margin: 0.1em;
    border: 1px solid black;
    border-radius: 6px;
    box-sizing: border-box;
    text-decoration: none;
    font-family: "poppins";
    font-weight: 300;
    color: white;
    background-color: #12202b;
    text-align: center;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  }
  button:hover {
    background-color: #fdf6bb;
  }
  input,
  textarea {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    width: 50%;
    padding: 10px;
    margin: 20px;
    border: solid 1px;
    border-radius: 10px;
  }
`;
