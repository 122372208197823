import Card from "./Card";

export const Postcard = (props) => {
    return (
        <Card>
            <h2>{props.title}</h2>
            
            <p>{props.content}</p>
            <p id="category">Category: {props.category}</p>
        </Card>
    )
}
export default Postcard




