import styled from "styled-components";

const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: space-between;
  
  border-radius: 10px;
  margin:20px;
  width: 300px;
  padding-top: 10px;
  padding-bottom: 20px;
  padding-left: 30px;
  padding-right: 50px;
 
  cursor: pointer;
  background-color: #2f4455;


  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  p, h2{
    -moz-user-select: none;
  user-select: none !important;
  margin-top:10px;
  
  }
  h2{
    text-transform: uppercase;
    font-size: 14pt;
    margin-bottom: 10px;
  }
  p{
    font-size: 12pt;
    margin-bottom: 10px;

  }
  &:hover{
    background-color: #12202b ;
  }

  }
  #stack, #category{
    font-size: 10pt;
    text-transform: capitalize;
  }
  @media (max-width: 700px) {
    padding: 20px;
    max-width: 70vw;;
 
  }

`;
export default Card