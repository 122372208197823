import { Link } from "react-router-dom";
import styled from "styled-components";
import logo from "./eamon.png";

function Head() {
  return (
    <Wrapper>
      <Link to="/">
        <div id="name">
          <img id="logo" src={logo} alt="Eamon Miller" />
        </div>
      </Link>
      <nav>
        <Link className="nav" to="/projects">
        💻Projects
        </Link>
        <Link className="nav" to="/blog">
        ✍️Blog
        </Link>
        <Link className="nav" to="/contact">
        💬Contact
        </Link>
      </nav>
    </Wrapper>
  );
}
export default Head;

const Wrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  text-transform: uppercase;
  display: flex;

  align-items: center;
  flex-wrap: wrap;
  
  width: 100%;
  justify-content: space-between;

  background-color: #2f4455;

  #logo {
    width: 200px;
    margin-top: 8px;
  }

  @media (max-width: 700px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-content: space-around;
    width: 100%;
    justify-content: space-between;
    nav {
      display: flex;
      justify-content: space-between !important;
      width: 100vw;
    }
    a {
      padding: 2px !important;
      margin: 4px !important;
    }
  }

  h1 {
    font-size: 20px;
    margin-left: 10px;
  }

  .nav {
    padding: 4px 14px;
    margin: 0.1em;
    border: solid 1px transparent;

    font-size: 20px;
    color: white;
    margin-left: 20px;
    text-decoration: none;
    //text-transform: uppercase;
  }

  .nav:hover {
    
    border-radius: 6px;
    box-sizing: border-box;
    background-color: #12202b;
  }
`;
