import styled from "styled-components";
import { Link } from "react-router-dom";

export default function About() {
  return (
    <Wrapper>
     

      
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin-top: 30vh;
  
  display: flex;

  flex-direction: column;
  justify-content: center;
  align-items: center;
  #top{
    margin-bottom: 20vh;
  }
  h3 {
    padding: 0px !important;
   
    font-size: 30px;
    
  }
  @media (max-width: 400px){
      h3{
        font-size: 24px;
      }
  }
  .buttons{
      margin-top: 10px;
      display: flex;
      flex-direction: row;
      gap: 20px;
  }
  a.button8 {
    display: inline-block;
    padding: 0.2em 1.45em;
    margin: 0.1em;
    border: 1px solid black;
    border-radius: 7px;
    box-sizing: border-box;
    text-decoration: none;
    font-family: "poppins";
    font-weight: 300;
    color: black;
    background-color: #fce3f1;
    text-align: center;
    position: relative;
  }
  a.button8:hover {
    background-color: #fdf6bb;
  }
`;
