import React from "react";
import ProjectCard from "./Projectcard";
import styled from "styled-components";
import Title from "./Title";

export default function Projects() {
  const projects = [
    {
      id: "1",
      title: "Speed Commanders 🛒",
      stack: "javascript, react-redux, stylled components",
      about:
        "Speed Commanders is an ecommerce website for the speedcommadners sunglasses brand. features a shopping cart and checkout proccess built from scratch using react-redux.",
      link: "https://speedcommander2022.pages.dev",
    },
    {
      id: "2",
      title: "CAD Crypto Tracker 🤑",
      stack: "Javascript, React",
      about: "Track cryptocurrencies price changes in Canadian dollars  ",
      link: "https://coin-gecko.pages.dev/",
    },
    {
      id: "3",
      title: "3d Rocketship Game 🚀",
      stack: "react, react-three-fiber",
      about: "a simple 3d game created with react and three.js",
      link: "https://three-fiber.pages.dev/",
    },
    {
      id: "4",
      title: "Portfolio 📑",
      stack: "Javascript, React, Threejs, Gsap",
      about: "i made this project for my portfolio ",
      link: "https://eamonmiller.com/",
    },
    {
      id: "5",
      title: "number 5",
      stack: "javascript, react, stylled components",
      about: "i made this project for my portfolio ",
      link: "https://speedcommander2022.pages.dev/",
    },
  ];

  return (
    <div>
      <Title>Projects</Title>

      <Wrapper>
        {projects.map((project) => (
          <ProjectCard
            key={project.id}
            title={project.title}
            stack={project.stack}
            about={project.about}
            link={project.link}
          />
        ))}
      </Wrapper>
    </div>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: center;
  column-gap: 10px;
  margin-left: 20vw;
  margin-right: 20vw;
  height: 700px;
  a{
    text-decoration: none;
    color: white;
  }
  @media (max-width: 700px) {
    margin-left: 4vw;
    margin-right: 4vw;
    flex-direction: row;
    height:140vh;
    justify-content: center;
  align-content: flex-start;
  }
`;
